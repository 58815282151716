import axios, { AxiosError, AxiosRequestHeaders, InternalAxiosRequestConfig } from 'axios';
import { GraphQLClient } from 'graphql-request';
import store from '../core/store';
import IdUtils from '../utils/id-utils';
import WorkspaceUtils from '../utils/workspace-utils';
export * from './types';

// SUB_PATHS
export const MDS_PATH = 'metadata-service/';
export const AUTHORIZATION_PATH = 'authorization-service/';
export const PRINCIPAL_PATH = 'principal-service/';
export const SERVER_GROUP_PATH = 'server-group-service/';

interface AxiosRequestOverrides {
  baseUrl: string;
  timeout: number;
  method: string;
  headers: AxiosRequestHeaders;
}

const getAxiosConfig = (servicePath: string) => {
  return {
    baseURL: `${window._env_.API_URL}${servicePath}`,
    timeout: 30000,
    method: 'POST',
    headers: {},
  };
};

const onRequestApplyAuth = (request: InternalAxiosRequestConfig<AxiosRequestOverrides>) => {
  const workspace = WorkspaceUtils?.getWorkspaceFromStore();
  if (workspace) request.headers['x-workspace'] = workspace;

  const requestId = IdUtils.generateStringId();
  request.headers['x-request-id'] = requestId;

  const auth = store.getState().auth.auth;
  if (auth != null) request.headers['Authorization'] = 'Bearer ' + auth.getAccessToken();

  return request;
};

const onRequestBuildHeaders = () => {
  const headers: any = {
    'Content-Type': 'application/json',
  };

  const workspace = WorkspaceUtils?.getWorkspaceFromStore();
  if (workspace) headers['x-workspace'] = workspace;

  const requestId = IdUtils.generateStringId();
  headers['x-request-id'] = requestId;

  const auth = store.getState().auth.auth;
  if (auth != null) headers['Authorization'] = 'Bearer ' + auth.getAccessToken();

  return headers;
};

const GRAPHQL_URI = process.env.REACT_APP_GRAPHQL_URI ?? '/graphql';

const checkStaleErrorResponse = async (error: AxiosError<any>) => {
  if (error.response?.status === 401) {
    //refresh token
    window.location.reload();
  }

  throw error;
};

export const Metadata_Service = axios.create(getAxiosConfig(`/v1/${MDS_PATH}`));
export const Authorization_Service = axios.create(getAxiosConfig(`/v1/${AUTHORIZATION_PATH}`));
export const Principal_Service = axios.create(getAxiosConfig(`/v1/${PRINCIPAL_PATH}`));
export const Server_Group_Service = axios.create(getAxiosConfig(`/v1/${SERVER_GROUP_PATH}`));
export const Tecton_Api = axios.create(getAxiosConfig(`/v1/`));
export const Tecton_Graphql = new GraphQLClient(GRAPHQL_URI, {
  headers: onRequestBuildHeaders,
});

Tecton_Api.interceptors.request.use(onRequestApplyAuth);
Tecton_Api.interceptors.response.use(undefined, checkStaleErrorResponse);

// Graphql
// Tecton_Graphql.interceptors.request.use(onRequestApplyAuth);
// Tecton_Graphql.interceptors.response.use(undefined, checkStaleErrorResponse);

// Service Specific instances
Metadata_Service.interceptors.request.use(onRequestApplyAuth);
Authorization_Service.interceptors.request.use(onRequestApplyAuth);
Principal_Service.interceptors.request.use(onRequestApplyAuth);
Server_Group_Service.interceptors.request.use(onRequestApplyAuth);
Metadata_Service.interceptors.response.use(undefined, checkStaleErrorResponse);
Authorization_Service.interceptors.response.use(undefined, checkStaleErrorResponse);
Principal_Service.interceptors.response.use(undefined, checkStaleErrorResponse);
Server_Group_Service.interceptors.response.use(undefined, checkStaleErrorResponse);
