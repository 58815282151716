import React, { FC } from 'react';

import { ReactComponent as Card } from './Illustrations/Card.svg';
import { ReactComponent as CardError } from './Illustrations/CardError.svg';
import { ReactComponent as Dataflow } from './Illustrations/Dataflow.svg';
import { ReactComponent as DataflowError } from './Illustrations/DataflowError.svg';
import { ReactComponent as EmptySearch } from './Illustrations/EmptySearch.svg';
import { ReactComponent as EmptyTable } from './Illustrations/EmptyTable.svg';
import { ReactComponent as EmptyTableError } from './Illustrations/EmptyTableError.svg';
import { ReactComponent as Issue } from './Illustrations/Issue.svg';
import { ReactComponent as IssueError } from './Illustrations/IssueError.svg';
import { ReactComponent as NoResults } from './Illustrations/NoResults.svg';
import { ReactComponent as NoResultsError } from './Illustrations/NoResultsError.svg';
import { ReactComponent as WalletEmpty } from './Illustrations/WalletEmpty.svg';
import { ReactComponent as WalletEmptyError } from './Illustrations/WalletEmptyError.svg';

type IllustrationType = 'Card' | 'Dataflow' | 'EmptySearch' | 'EmptyTable' | 'Issue' | 'NoResults' | 'WalletEmpty';

interface IllustrationProps {
  illustration: IllustrationType;
  error?: boolean;
}

const IllustrationTypeMap: Record<IllustrationType, React.ReactNode> = {
  Card: <Card />,
  Dataflow: <Dataflow />,
  EmptySearch: <EmptySearch />,
  EmptyTable: <EmptyTable />,
  Issue: <Issue />,
  NoResults: <NoResults />,
  WalletEmpty: <WalletEmpty />,
};

const IllustrationTypeMapError: Record<IllustrationType, React.ReactNode> = {
  Card: <CardError />,
  Dataflow: <DataflowError />,
  EmptySearch: <EmptySearch />,
  EmptyTable: <EmptyTableError />,
  Issue: <IssueError />,
  NoResults: <NoResultsError />,
  WalletEmpty: <WalletEmptyError />,
};

const Illustration: FC<IllustrationProps> = ({ illustration, error = false }) => {
  if (error) {
    return <>{IllustrationTypeMapError[illustration]}</>;
  }

  return <>{IllustrationTypeMap[illustration]}</>;
};

export default Illustration;
